.ql-editor {
  height: 33vh;
}

.ql-editor.ql-blank::before {
  color: grey;
}

.submit-button {
  margin-top: 15px;
  float: right;
  color: darkgrey;
  background-color: black;
  border-radius: 25px;
  border: none;
  height: 2rem;
}

.submit-button:hover {
  border: 1px solid #fff;
  border-radius: 25px;
  cursor: pointer;
}

.submit-button:focus {
  border: 1px solid #fff;
  border-radius: 25px;
  cursor: pointer;
}

.editor-title {
  margin-left: 10px;
}
.input-field {
  color: darkgrey;
  height: 2rem;
  border: 1px solid grey;
  background: #000;
  width: 50%;
  outline: none;
}
