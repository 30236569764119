blockquote {
  font-family: American Typewriter, Times New Roman, serif;
  font-style: oblique;
  font-weight: bold;
  color: rgba(250, 200, 130, 0.6);
  background-color: #3d3d3d;
  padding: 2px;
  text-indent: 10px;
}
blockquote:before {
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote:after {
  content: close-quote;
  font-size: 3em;
  margin-left: 0.05em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
p {
  text-indent: 10px;
}
hr {
  height: 1px;
  color: rgba(255, 104, 104, 0.5);
  background-color: black;
  border-top: 1px solid black;
}
